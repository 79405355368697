import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../components/service-hero";
import OurServicesSection from "../components/our-services-section";
import Reviews from "../components/reviews";
import ContactForm from "../components/contact-form";
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import { SiGooglemybusiness } from "react-icons/si";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "contact-us" }) {
        contactUsFields {
          contactBanner {
            contactBannerHeading
            contactBannerBackgroundImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: TRACED_SVG
                    )
                  }
                }
              }
            }
          }
          contactGetInTouchSection {
            contactGetInTouchHeading
            contactGetInTouchNumber
            contactEmails {
              contactEmailAddress
              contactEmailLabel
            }
            contactGetInTouchAddress
            contactOpeningTimeText
            contactInstagramUrl
            contactTwitterUrl
            contactFacebookUrl
            contactLinkedinUrl
            contactWatsappUrl
            contactGoogleMapUrl
          }
          contactReviews {
            contactReviewsHeading
          }
          contactServicesCardsSection {
            contactServicesCardsHeading
            contactServicesCards {
              nodes {
                ... on WpService {
                  title
                  servicesPostFields {
                    serviceUrl {
                      target
                      title
                      url
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFields, contactUsFields },
  } = data;

  const {
    contactBanner,
    contactGetInTouchSection,
    contactReviews,
    contactServicesCardsSection,
  } = contactUsFields;

  const {
    contactGetInTouchHeading,
    contactGetInTouchNumber,
    contactEmails,
    contactGetInTouchAddress,
    contactOpeningTimeText,
    contactInstagramUrl,
    contactTwitterUrl,
    contactFacebookUrl,
    contactLinkedinUrl,
    contactWatsappUrl,
    contactGoogleMapUrl,
  } = contactGetInTouchSection;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Contact Us",
        item: {
          url: `${siteUrl}/contact-us`,
          id: `${siteUrl}/contact-us`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div style={{ overflow: "hidden" }}>
        {contactBanner && (
          <section className=" pb-xl-5">
            <ServiceHero
              title={contactBanner.contactBannerHeading}
              backgroundImage={
                contactBanner.contactBannerBackgroundImage.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
            />
          </section>
        )}

        <section className=" py-5 py-lg-8">
          <Container>
            <Row>
              <Col
                className="pt-5 pt-lg-0 text-center text-lg-start"
                xs={{ span: 12, order: "last" }}
                lg={{ span: 5, order: "first" }}
              >
                <h2 className="d-none d-lg-block">
                  {contactGetInTouchHeading || "GET IN TOUCH"}
                </h2>{" "}
                <a
                  className=" black-link phone-link-cta d-block pb-3"
                  href={`tel:+44${contactGetInTouchNumber}`}
                >
                  0{contactGetInTouchNumber}
                </a>
                {contactEmails &&
                  contactEmails.length > 0 &&
                  contactEmails.map((contactItem) => (
                    <p>
                      {contactItem.contactEmailLabel}:{" "}
                      <a
                        className="black-link pb-3 email-link-cta  "
                        href={`mailto:${contactItem.contactEmailAddress}`}
                      >
                        {contactItem.contactEmailAddress}
                      </a>
                    </p>
                  ))}
                <p>
                  <SafeHtmlParser htmlContent={contactGetInTouchAddress} />
                </p>
                <p className="inter-medium">
                  <SafeHtmlParser htmlContent={contactOpeningTimeText} />
                </p>
                <div className="d-block  ">
                  <ul className="ps-0 mb-1">
                    {contactInstagramUrl && (
                      <li className="d-inline-block me-3 ">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="black-link fs-3 social-icon"
                          href={contactInstagramUrl}
                        >
                          <FaInstagram />
                        </a>
                      </li>
                    )}

                    {contactTwitterUrl && (
                      <li className="d-inline-block me-3">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="black-link fs-3 social-icon"
                          href={contactTwitterUrl}
                        >
                          <FaTwitter />
                        </a>
                      </li>
                    )}
                    {contactFacebookUrl && (
                      <li className="d-inline-block me-3 ">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className=" black-link fs-3 social-icon"
                          href={contactFacebookUrl}
                        >
                          <FaFacebook />
                        </a>
                      </li>
                    )}

                    {contactLinkedinUrl && (
                      <li className="d-inline-block me-3 ">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className=" black-link fs-3 social-icon"
                          href={contactLinkedinUrl}
                        >
                          <FaLinkedin />
                        </a>
                      </li>
                    )}

                    {contactWatsappUrl && (
                      <li className="d-inline-block me-3 ">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className=" black-link fs-3 social-icon whatsapp-link-cta"
                          href={contactWatsappUrl}
                        >
                          <FaWhatsapp className="whatsapp-link-cta" />
                        </a>
                      </li>
                    )}

                    {contactGoogleMapUrl && (
                      <li className="d-inline-block  ">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className=" black-link fs-3 social-icon"
                          href={contactGoogleMapUrl}
                        >
                          <SiGooglemybusiness />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>
              <Col className="ps-lg-5" lg={7}>
                <h2 className="mb-3 d-lg-none">
                  {contactGetInTouchHeading || "GET IN TOUCH"}
                </h2>{" "}
                <ContactForm />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="bg-grey">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2522.2791361206628!2d0.30413441571033123!3d50.7889352795236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47df73bbc404c3f1%3A0xdea4039e2c6fae37!2sECF%20Accident%20Repair%2C%20Servicing%20%26%20MOT%20Centre.!5e0!3m2!1sen!2suk!4v1654963390711!5m2!1sen!2suk"
            frameBorder="0"
            aria-hidden="false"
            title="google-map"
            loading="lazy"
            width="100%"
            height="500"
            style="border:0;"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
        <section>
          <Reviews title={contactReviews?.contactReviewsHeading} />
        </section>
        {contactServicesCardsSection &&
          !checkPropertiesForNull(contactServicesCardsSection, [
            "contactServicesCardsHeading",
          ]) && (
            <OurServicesSection
              title={contactServicesCardsSection.contactServicesCardsHeading}
              services={contactServicesCardsSection.contactServicesCards?.nodes}
            />
          )}
      </div>
    </Layout>
  );
};

export default ContactUsPage;
